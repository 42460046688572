import React from "react";
import Tagline from "../Components/Tagline/Tagline.component";
import Projects from "../Components/Projects/Projects";
import Skills from "../Components/Skills/Skills";
import { interests, skills } from "../Utilities/Constants";

const Home = () => {
    return (
        <div>
            <main>
                <Tagline />
                <Projects />
                <Skills title="Skills" data={skills} />
                <Skills title="I TALK A LOT ABOUT" data={interests} />
            </main>
            {/* <ScrollToTop /> */}
        </div>
    )
}

export default Home;