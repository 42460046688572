import React from "react";
import IndividualProject from "../Components/Projects/IndividualProject";

const Work = () => {
    return (
        <main>
            <IndividualProject />
        </main>
    )
}

export default Work;