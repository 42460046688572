const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://bshah17-uncc.github.io/',
  title: 'Bhavain Shah',
}

const about = {
  name: 'Bhavain Shah',
  main_tagline: "a software engineer, who likes solving complex problems. I'm good at coding and better at debugging.",
  sub_tagline: 'Writing code in the morning & fighting crime in the night!',
  role: 'Software Engineer',
  description:
    'Hi, I am Bhavain Shah, and I enjoy solving problems. I am good coder, and a better debugger.',
  // resume: 'https://example.com',
  // social: {
  //   linkedin: 'https://linkedin.com',
  //   github: 'https://github.com',
  // },
}

const projects = [
  {
    id: "my-alumni-network",
    name: 'My Alumni Network',
    subtitle: "Mobile App Architecture & Development",
    description:
      'My Alumni Network is a mobile app developed on both Android and iOS that helps students and alumni connect with each other. It is a platform that allows users to stay in touch with their school, college, or university and to connect with other alumni for networking and other opportunities.',
    stack: ['React Native', 'Rest APIS', 'Firebase', 'Socket.io', 'TypeScript', 'JavaScript', 'Redux', 'MaterialUI', 'Xcode', 'Android Studio'],
    images: [
      require("../Assets/Work/MyAlumniNetwork/my-alumni-network-mockup1.png"),
      require("../Assets/Work/MyAlumniNetwork/my-alumni-network-mockup2.png"),
      require("../Assets/Work/MyAlumniNetwork/my-alumni-network-mockup3.png"),
    ]
    // sourceCode: 'https://github.com',
    // livePreview: 'https://github.com',
  },
  {
    id: "spaces",
    name: 'Spaces',
    subtitle: "Mobile App Development",
    description:
      'Spaces is a real time chat application that allows users to create chat rooms and chat with other users in their community.',
    stack: ['React Native', 'CometChat', 'TypeScript'],
    images: [
      require("../Assets/Work/Spaces/spaces-mockup1.png"),
      require("../Assets/Work/Spaces/spaces-mockup2.png"),
      require("../Assets/Work/Spaces/spaces-mockup3.png"),
    ]
    // sourceCode: 'https://github.com',
    // livePreview: 'https://github.com',
  },
  {
    id: "hash8",
    name: 'Hash8',
    subtitle: "Mobile & Web App Development",
    description:
      'Hash8 is a mobile and web app that allows users to connect with each other through audio & video calls. It was designed to increase engagemnt and interaction between students, faculty and alumni.',
    stack: ['React Native', 'Agora.io', 'JavaScript', 'Vue.js', 'PHP'],
    images: [
      require("../Assets/Work/Hash8/hash8-mockup1.png"),
      require("../Assets/Work/Hash8/hash8-mockup2.png"),
      require("../Assets/Work/Hash8/hash8-mockup3.png"),
    ]
    // sourceCode: 'https://github.com',
    // livePreview: 'https://github.com',
  },
]

const skills = [
  "React Native",
  "JavaScript",
  "Python",
  "Java",
  "C/C++",
  "SQL",
  "TypeScript",
  "Docker",
  "ES6",
  "AWS EC2",
  "MongoDB",
  "Firebase",
  "Git",
  "GitHub",
  "Node.js",
  "Express.js",
  "React.js",
  "Sails.js",
  "Vue.js",
  "Redux",
  "Bootstrap",
  "Android Studio",
  "Xcode",
  "Android Development",
  "iOS Development",
  "Game Development",
  "GameMaker",
  "Objective-C",
  "HTML",
  "CSS"
];

const interests = [
  "Football", "Cooking", "Poetry", "Music", "Movies", "TV Shows", "Video Games", "Traveling", "Gardening", "Hiking"
];

const contact = {
  email: 'bhavainshah@gmail.com',
  phone: '+1 704 733 8551',
  linkedin: 'https://www.linkedin.com/in/bhavainshah/',
  github: 'https://github.com/bhavain',
}

export { header, about, projects, skills, contact, interests }
