import React from 'react';
import { about } from '../../Utilities/Constants';
import './Tagline.css';

const Tagline = () => {
    const {main_tagline, sub_tagline} = about;
    
    return (
        <section id="about" className="centered-section">
            <div className='sub-tagline'>{sub_tagline}</div>
            <div className="main-tagline">
                <span style={{color: "#90939B"}} className="highlight">Hi, It's Bhavain - </span>
                {main_tagline}
            </div>
        </section>
    )
}

export default Tagline;