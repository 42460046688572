import React, { useContext } from 'react';
import './App.css';
import { ThemeContext } from './contexts/theme';
import Home from './Screens/Home';
import About from './Screens/About';
import Lost from './Screens/Lost';
import { Route, Routes } from 'react-router-dom';
import Work from './Screens/Work';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';

function App() {
  const [{ themeName }] = useContext(ThemeContext);

  return (
    <div id='top' className={`${themeName} app`}>
      <Header />
      <Routes>
        <Route path="/" >
          <Route index element={<Home />} />
          <Route path='work' element={<Work />} >
            <Route path=':work_id' />
          </Route>
          <Route path="about" element={<About />} />
          <Route path="*" element={<Lost />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
