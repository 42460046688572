import { useState } from 'react'
// import Brightness4Icon from '@mui/icons-material/Brightness4';
// import WbSunnyIcon from '@mui/icons-material/WbSunny';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
// import { ThemeContext } from '../../contexts/theme'
import './Navbar.css'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  // const [{ themeName, toggleTheme }] = useContext(ThemeContext)
  const [showNavList, setShowNavList] = useState(false)

  const toggleNavList = () => setShowNavList(!showNavList);
  const navigate = useNavigate();

  // const scrollToSection = (sectionId) => {
  //   const element = document.getElementById(sectionId);
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  return (
    <nav className='nav center'>
      <ul
        style={{ display: showNavList ? 'flex' : null }}
        className='nav__list'
      >
        <li className='nav__list-item'>
          <a
            href='#projects'
            onClick={() => {
              toggleNavList()
              navigate("/")
            }}
            className='link link--nav'
          >
            Work
          </a>
        </li>

        {/* <li className='nav__list-item'>
          <a
            href='#'
            onClick={() => {
              toggleNavList()
              navigate("about")
            }}
            className='link link--nav'
          >
            About Me
          </a>
          
        </li> */}

        <li className='nav__list-item'>
          <a
            href='#contact'
            onClick={toggleNavList}
            className='link link--nav'
          >
            Contact
          </a>
        </li>
      </ul>

      {/* <button
        type='button'
        onClick={toggleTheme}
        className='btn btn--icon nav__theme'
        aria-label='toggle theme'
      >
        {themeName === 'dark' ? <WbSunnyIcon /> : <Brightness4Icon />}
      </button> */}

      <Button
        color='inherit'
        onClick={toggleNavList}
        className='nav__hamburger'
        aria-label='toggle navigation'
      >
        {showNavList ? <CloseIcon /> : <MenuIcon />}
      </Button>
    </nav>
  )
}

export default Navbar
  
  // <div className="header-options">
  //   </div>