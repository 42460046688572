import React from "react";

const Lost = () => {
    return (
        <main>
            <h1>404: Page not found</h1>
        </main>
    )
}

export default Lost;