import { header } from '../../Utilities/Constants';
import Navbar from '../Navbar/Navbar'
import './Header.css'

const Header = () => {
  const { homepage, title } = header

  return (
    <header className='header center'>
      <a href={homepage} className='header-name' style={{color: "#1F1F1F", textDecorationLine: "none"}}>
        {title}
      </a>
      <Navbar />
    </header>
  )
}

export default Header
