import React from "react";
import { useParams } from "react-router-dom";
import { projects } from "../../Utilities/Constants";
import "./IndividualProject.css";
import Skills from "../Skills/Skills";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from "@mui/material";

const IndividualProject = () => {
let { work_id } = useParams();
const project = projects.find((project) => project.id === work_id);
  const { description, images, name, stack, subtitle } = project;
    return (
        <div className="project-wrapper">
            <Button className="back-icon" color="inherit" onClick={() => window.history.back()} style={{ cursor: 'pointer' }}>
                <ArrowBackIcon  />
            </Button>
            <div>
                <div className="container">
                    <span className="title">{name}</span>
                    <span className="subtitle">{subtitle}</span>
                    <p className="description">
                    {description}
                    </p>
                    <div className="image-container">
                        <img src={images[1]} alt="Image 1" className="image image1" />
                        <img src={images[2]} alt="Image 2" className="image image2" />
                        <img src={images[0]} alt="Image 3" className="image image3" />
                    </div>
                    <span className="skills-heading">Development Specs</span>
                </div>
                <Skills data={stack} title="" />
            </div>
        </div>
    )
}

export default IndividualProject;