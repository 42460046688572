// import uniqid from 'uniqid'
// import GitHubIcon from '@material-ui/icons/GitHub'
// import LaunchIcon from '@material-ui/icons/Launch'
import React from 'react'
import './ProjectContainer.css'
import { useNavigate } from "react-router-dom";

const ProjectContainer = ({ project }) => {
  const { id, images, name, subtitle } = project;
  const navigate = useNavigate();

  const navigateToProject = () => {
    navigate(`/work/${id}`);
  }

  return (
    <div className='project'>
      <img className='project__banner' src={images[0]} alt="Work Item 1" onClick={navigateToProject} />
      <div className='project__title__container' style={id === "hash8" ? {backgroundColor: '#641CD7'} : null}>
        <div className='project__title'>{name}</div>
        <div className='project__title__break'>•</div>
        <div className='project__subtitle'> {subtitle}</div>
      </div>
      {/* <p className='project__description'>{project.description}</p>
      {project.stack && (
        <ul className='project__stack'>
          {project.stack.map((item) => (
            <li key={uniqid()} className='project__stack-item'>
              {item}
            </li>
          ))}
        </ul>
      )} */}

      {/* {project.sourceCode && (
        <a
          href={project.sourceCode}
          aria-label='source code'
          className='link link--icon'
        >
          <GitHubIcon />
        </a>
      )}

      {project.livePreview && (
        <a
          href={project.livePreview}
          aria-label='live preview'
          className='link link--icon'
        >
          <LaunchIcon />
        </a>
      )} */}
    </div>
  )
}

export default ProjectContainer
