import React from "react";

const About = () => {
    return (
        <main>
            <h1>About</h1>
        </main>
    )
}

export default About;