import { about, contact } from '../../Utilities/Constants';
import './Contact.css'
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import { Button } from '@mui/material';

const Contact = () => {
  const { email, github, linkedin, phone } = contact;
  const { name, role } = about;

  const handleIconClick = (icon) => {
    switch(icon) {
      case "mail":
        window.open(`mailto:${email}`, "_blank");
        break;
      case "linkedin":
        window.open(linkedin, "_blank");
        break;
      case "github":
        window.open(github, "_blank");
        break;
      default:
        break;
    }
  }
  return (
    <section className='section contact' id='contact'>
      <div className='contact__container'>
        <span className='contact__name'>{name}</span>
        <span style={{color: "#90939B", marginBottom: '8px'}}>{role}</span>
        <div className='contact_wrapper'>
          <span>{phone}</span>
          <div>
            <Button color="inherit" onClick={() => handleIconClick("mail")} style={{ cursor: 'pointer', marginRight: '16px' }}>
              <EmailIcon color='#1F1F1F' fontSize='large' />
            </Button>
            <Button color="inherit" onClick={() => handleIconClick("linkedin")} style={{ cursor: 'pointer' }}>
              <LinkedInIcon color='#1F1F1F' fontSize='large' />
            </Button>
            <Button color="inherit" onClick={() => handleIconClick("github")} style={{ cursor: 'pointer', marginLeft: '16px' }}>
              <GitHubIcon color='#1F1F1F' fontSize='large' />
            </Button>
          </div>
        </div>
        </div>
    </section>
  )
}

export default Contact